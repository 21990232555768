import React, { useMemo } from 'react'
import qs from 'qs'
import { useLocation } from '@reach/router'
import { useAsync, useAsyncFn } from 'react-use'
import DonationForm from './DonationForm'
import { API_URL } from '@src/utils'

const apiFetch = (path, params) =>
  fetch(API_URL + path, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    ...params,
    body: JSON.stringify(params.body),
  })

const DonationFormConnected = ({
  amount,
  dim2,
  campaign,
  itemCode,
  monthly,
  zenterAudience,
  onSuccess,
}) => {
  const location = useLocation()
  const { donationId, errorCode } = useMemo(
    () => qs.parse(location.search.substring(1)),
    [],
  )
  const errorMessages = {
    UNKNOWN: 'Eitthvað fór úrskeiðis. Hafðu samband við Unicef.',
    THREE_DS_FAILED: '3DS auðkenning mistókst',
  }
  const paymentError = {message: errorMessages[errorCode]}
  // check success if user is redirected back from donation page
  const donation = useAsync(async () => {
    if (!donationId || paymentError.message) return

    const r = await apiFetch('/api/donate/get', {
      body: {
        donationId,
      },
    })
    const data = await r.json()
    if (r.ok) {
      onSuccess(data)
    }
    return data
  }, [donationId])

  // form submit handler
  const [submit, onSubmit] = useAsyncFn(
    async (formData) => {
      const r = await apiFetch('/api/donate', {
        body: {
          ...formData,
          back: location.href,
          amount,
          monthly,
          campaign,
          itemCode,
          dim2,
          audiences: zenterAudience,
        },
      })
      const data = await r.json()
      if (r.ok) {
        if(data.location) {
          window.location.href = data.location
        }
      }
      return data
    },
    [amount, monthly, campaign, itemCode, dim2],
  )

  // When user returns from rapyd we look up the donation by id query parameter.
  // Hopefully fast enough to not require a loading indicator
  if (donationId && donation.loading) return null

  return (
    <DonationForm
      isSubmitting={submit.loading || Boolean(submit.value?.location)}
      onSubmit={onSubmit}
      amount={amount}
      monthly={monthly}
      error={donation.error || submit.value?.error || paymentError}
      campaign={campaign}
    />
  )
}

export default DonationFormConnected
