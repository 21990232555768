import React from 'react'
import styles from './ImageGallery.module.scss'
import {
  Container,
  Row,
  handleImgixUrl
} from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { SplitHeading } from '@cmp/site'
import Img from 'gatsby-image'
import fallback from '../../../assets/logos/unicef-white-stacked.png'

export default ({ heading, images }) => {
  return (
    <Container fluid className='py-5 py-md-5 mt-lg-5'>
      <Row>
        <div className='col offset-md-1'>
          <SplitHeading
            tag='h3'
            classNames='hdln--3 hdln--black-blue'
          >
            {heading}
          </SplitHeading>
        </div>
      </Row>
      <Row className='d-flex'>
        {images.length > 0 &&
          images.map(({
            link_name: { text: linkName },
            link,
            image
          }, index) => {
            const fluid = image.url ? handleImgixUrl(image.url).gatsbyImageFluid() : null
            const fluidMobile = fluid && image.thumbnails.mobile ? handleImgixUrl(image.thumbnails.mobile.url).gatsbyImageFluid() : null
            const mobile = fluidMobile ? fluidMobile : fluid

            return (
              <div key={index} className='col-6 col-lg-4 col-xl-3 px-1 px-sm-2 mt-2 mt-md-3'>
                <Link
                  to={link.url}
                >
                  <div className={styles.imageWrapper}>
                    {fluid
                      ? <>
                        <Img
                          className={cn(
                            styles.image,
                            'd-none d-lg-block'
                          )}
                          loading='lazy'
                          alt={image.alt}
                          fluid={fluid} />
                        <Img
                          className={cn(
                            styles.image,
                            'd-block d-lg-none'
                          )}
                          loading='lazy'
                          alt={image.alt}
                          fluid={mobile} />
                      </>
                      : <div className={styles.fallbakcWrapper}>
                        <div className={styles.fallback} style={{ backgroundImage: `url(${fallback})` }} />
                      </div>
                    }
                  </div>
                  <div className={styles.heading}>
                    <SplitHeading
                      tag='h5'
                      textCol
                      classNames='hdln--with-bg hdln--blue-bg'
                    >
                      {linkName}
                    </SplitHeading>
                  </div>
                </Link>
              </div>

            )
          })
        }
      </Row>
    </Container>
  )
}
